import React from "react";
import GridLoader from "react-spinners/GridLoader";
import { useTranslation } from "react-i18next";

import "../../Assets/CSS/Reset.css";

function Loading({ errorAlert }) {
  const { t } = useTranslation();
  return (
    <div className="loading_container">
      <div className="overlay"></div>
      {errorAlert == true && <p>{t("Connection error")}</p>}
      <GridLoader
        color={"#fff"}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default Loading;
