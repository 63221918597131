import axios from "axios";
import { generateRefreshToken, logout } from "../Services/LoginServices";
import jwt from "jwt-decode";
import Cookies from "js-cookie";
const instance = axios.create(process.env.baseURL);

instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },

  async function (error) {
    const status = (error && error.response && error.response?.status) || 500;
    switch (status) {
      // authentication (token related issues)
      case 401: {
        await logout();
        window.location.href = "/";
      }

      // forbidden (permission related issues)
      case 403: {
        let token = await generateRefreshToken();
        document.cookie = `refreshToken=${token.refreshToken}`;
        const decodeRefreshToken = await jwt(Cookies.get("refreshToken"));
        const expRefreshToken = new Date(decodeRefreshToken.exp * 1000);
        Cookies.set("refreshToken", Cookies.get("refreshToken"), {
          expires: expRefreshToken,
        });
      }

      // bad request
      case 400: {
        return Promise.reject(error);
      }

      // not found
      case 404: {
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      }

      // conflict
      case 409: {
        return Promise.reject(error);
      }

      // unprocessable
      case 422: {
        return Promise.reject(error);
      }

      case 500: {
      }

      // generic api error (server related) unexpected
      default: {
        return Promise.reject(error);
      }
    }
  }
);

export default instance;
