import React from "react";
import "../../Assets/CSS/Modal/Modal.css";
import { HiArrowLongRight } from "react-icons/hi2";
import { useTranslation } from "react-i18next";

function SearchModal({  }) {
  const { t } = useTranslation();

  return (
    <div className="search_modal_container">
      <h1 className="modal_heading">{t("Search")}</h1>
      <div className="modal_Body">
        <input
          className="modal_search_input"
          autoFocus
          placeholder={t("Enter your item code...")}
        />
        <div className="modal_item modal_search_feed">
          <div className="modal_content modal_search_item">
            <HiArrowLongRight className="search_feed_icon" />
            <span className="search_feed_name_item">Item name</span>
          </div>
          <div className="modal_content modal_search_item">
            <HiArrowLongRight className="search_feed_icon" />
            <span className="search_feed_name_item">Item name</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchModal;
