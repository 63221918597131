import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import React from "react";
import { createRoot } from "react-dom/client";
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


root.render(
  <BrowserRouter basename={baseUrl}>
      <App />
  </BrowserRouter>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
