export const STATUS_NOT_SUBMIT = "notSubmit_1";
export const STATUS_ACK = "ack_2";
export const STATUS_SUP_RETURN = "supReturn_3";
export const STATUS_SUBMIT = "submit_4";
export const STATUS_FOV_RETURN = "fovReturn_5";
export const STATUS_APPROVE = "approve_6";
export const STATUS_CHECKING = "checking_7";

export const REQUIREMENT_TYPE_01 = [
  "ROHS",
  "REACH",
  "EU",
  "MDR",
  "surver sheet",
  "PFAS",
  "CP65",
  "CSTA",
];

export const REQUIREMENT_TYPE_02 = ["CMRT", "3TG", "EMRT", "CRT"];
