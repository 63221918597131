import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import react-icons
import { BiSolidUserAccount, BiSolidExit } from "react-icons/bi";

import "../../Assets/CSS/Modal/Modal.css";
import { logout } from "../../Services/LoginServices";
import { toast } from "react-toastify";

function UserModal({ setIsShowModal, isBlockClick }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoSupplierProfile = () => {
    if (!isBlockClick) {
      navigate("/profile");
    } else {
      toast.error("please close or submit your result");
    }
  };

  const handleLogout = async () => {
    if (!isBlockClick) {
      var checkLogout = await logout();
      if (checkLogout.code == 1) {
        window.location.href = "/";
      }
    } else {
      toast.error("please close or submit your result");
    }
  };

  return (
    <div className="user_profile_modal_container">
      <div className="modal_Body">
        <div className="modal_item">
          <div onClick={handleGoSupplierProfile} className="modal_content">
            <span>
              <BiSolidUserAccount className="user_profile_logOut_icon" />
            </span>
            {t("Profile")}
          </div>
          <div onClick={handleLogout} className="modal_content">
            <span>
              <BiSolidExit className="user_profile_logOut_icon" />
            </span>{" "}
            {t("Logout")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserModal;
