import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import "../../Assets/CSS/Modal/Modal.css";
//import fujukura logo
import { AppContext } from "../../App";
import { STATUS_NOT_SUBMIT } from "../../Constraint/constraint";
import { Link } from "react-router-dom";

function NotificationModal({ data }) {
  const appContext = useContext(AppContext);
  const request = appContext.request;
  const { t } = useTranslation();
  return (
    <div className="notification_modal_container">
      <h1 className="modal_heading">{t("Notification")}</h1>
      <div className="modal_Body">
        <div className="modal_item modal_nof_item">
          <div className="modal_content modal_nof_content">
            {request &&
              request
                .filter((item) => item.statusID == STATUS_NOT_SUBMIT)
                .map((item, index) => {
                  return (
                    <div key={index} className="modal_nof_infor">
                      <div className="modal_nof_order">{index + 1}</div>
                      <div className="modal_nof_content">
                        <div className="modal_nof_heading">
                          {t("New Request")}
                        </div>
                        <div className="modal_nof_desc">
                          <span>{t("Item Code")}: </span>
                          {item.itemCode}
                        </div>
                        <div className="modal_nof_desc">
                          <span>{t("Item Name")}: </span>
                          {item.itemName}
                        </div>
                        <div className="modal_nof_desc">
                          <span>{t("Requirement")}: </span>
                          {item.requirement}
                        </div>
                      </div>
                      <div className="modal_nof_time">
                        {item.dueDate && item.dueDate.split("T")[0]}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <Link to="/supplier-request">{t("View")}</Link>
      </div>
    </div>
  );
}

export default NotificationModal;
