import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

function LanguageModal(props) {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const setLanguage = appContext.setLanguage;

  return (
    <div className="language_modal_container">
      <p onClick={() => setLanguage("vn")}>{t("Vietnamese")}</p>
      <p onClick={() => setLanguage("en")}>{t("English")}</p>
    </div>
  );
}

export default LanguageModal;
