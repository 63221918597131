import React, { createContext, useEffect, useState } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./Assets/CSS/App.css";
import "./Assets/CSS/Reset.css";

import { ToastContainer } from "react-toastify";
import AppRoutes from "./Routers/AppRoutes";
import Sidebar from "./Components/RemoteControl/Sidebar/Sidebar";
import Navigation from "./Components/RemoteControl/Navigation/Navigation";
import { getFovRequest } from "./Services/FovRequestServices";
import { STATUS_NOT_SUBMIT } from "./Constraint/constraint";

export const AppContext = createContext();

function App() {
  const [currentSideHeading, setCurrentSideHeading] = useState("DASHBOARD");
  const [request, setRequest] = useState([]);
  const [token, setToken] = useState();
  const [tag, setTag] = useState("sidebar_item_id_0");
  const [isShowFullSidebar, setIsShowFullSidebar] = useState(true);
  const [isShowController, setIsShowController] = useState(true);
  const [language, setLanguage] = useState("en");
  const [countNotSubmit, setCountNotSubmit] = useState(0);
  const [isBlockClick, setIsBlockClick] = useState(false);
  const [supplierCode, setSupplierCode] = useState("");
  i18n.use(initReactI18next).init({
    resources: {
      vn: {
        translation: {
          /* LOGIN */
          Login: "Đăng nhập",
          "Supplier code": "Tài khoản",
          "Submit your result to FOV now!": "Đăng nhập để gửi kết quả cho FOV ngay!",
          "Incorrect supplier code or password":
            "Tài khoản hoặc mật khẩu không đúng",
          "Enter your supplier code...": "Nhập mã nhà cung cấp...",
          "Your password...": "Nhập mật khẩu...",
          "Forget password": "Quên mật khẩu",
          Password: "Mật khẩu",
          /* END LOGIN */
          /* CONTROL */
          History: "Lịch sử",
          Request: "Yêu cầu",
          HISTORY: "LỊCH SỬ",
          history: "lịch sử",
          request: "yêu cầu",
          "Form Download": "Biểu mẫu",
          DASHBOARD: "BẢNG THỐNG KÊ",
          dashboard: "bảng thống kê",
          "Upcoming deadlines": "Đến hạn",
          /* END CONTROL */
          /* MODAL */
          Search: "Tìm kiếm",
          "Enter your item code...": "Nhập mã mục...",
          Notification: "Thông báo",
          "New Request": "Yêu cầu mới",
          "Request Description": "Mô tả yêu cầu",
          Profile: "Tài khoản",
          "Logout": "Đăng xuất",
          Vietnamese: "Tiếng Việt",
          English: "Tiếng Anh",

          /* END MODAL */

          /* DASHBOARD */
          "Total Request": "Tổng yêu cầu",
          "Not Submitted": "Chưa gửi",
          "FOV Return": "FOV hoàn trả",
          "Sup ACK": "Nhà cung cấp tiếp nhận",
          "Sup Return": "Nhà cung cấp hoàn trả",
          "See more History": "Xem thêm lịch sử",
          "Total Request Item": "Tổng mục yêu cầu",
          "Not Submitted Item": "Mục chưa gửi",
          "Submitted Item": "Mục đã gửi",
          "Approved Item": "Mục thông qua",
          "FOV Return Item": "Mục FOV hoàn trả",
          "ACK Item": "Mục tiếp nhận",
          "Return Item": "Mục hoàn trả",
          "View": "Xem thêm",
          /* TABLE */
          "Request No": "Mã yêu cầu",
          "upcoming deadlines": "đến hạn",
          "Item Name": "Tên mục",
          Requirement: "Luật",
          Version: "Phiên bản",
          "Due Date": "Ngày hết hạn",
          Status: "Trạng thái",
          Specification: "Thông số",
          "Request Date": "Ngày yêu cầu",
          "Last Declare": "Khai báo",
          "FOV Comment": "FOV phản hồi",
          "item selected": "mục chọn khai báo",
          "Item Code": "Mã mục",
          ver: "phiên bản",

          /* CHART */
          Submitted: "Đã gửi",
          Approved: "Chấp thuận",
          ACK: "Xác nhận",
          "Request Statistics Chart": "Biểu đồ phân tích yêu cầu",

          /* REQUEST */
          "Declare for Selected": "Khai báo dòng đã chọn",
          Return: "Hoàn trả",
          "Recall Return": "Thu hồi hoàn trả",
          Done: "Hoàn tất",
          "Go back": "Trở lại",
          Note: "Chú ý",
          " Each request with a different state can only do certain actions. To avoid error please check status of request before do any action.":
            " Mỗi yêu cầu với trạng thái khác nhau chỉ có thể làm một số hành động nhất đinh. Để tránh lỗi vui lòng kiểm tra trạng thái trước khi thực hiện thao tác.",
          Declare: "Khai báo",
          Recall: "Thu hồi",
          "Select at least one item": "Chọn ít nhất một item",
          "Please enter reason return to FOV":
            "Vui lòng nhập lý do hoàn trả lại FOV",
          "Return successfully": "Hoàn trả thành công",
          "Recall return successfully": "Thu hồi hoàn trả thành công",
          "Change to ack successfully": "Tiếp nhận yêu cầu thành công",
          "Submitted result successfully": "Gửi kết quả thành công",
          "Are you sure to": "Bạn có chắc",
          "for item selected": "cho mục đã chọn",
          Reason: "Lý do",
          Confirm: "Xác nhận",
          Detail: "Chi tiết",
          Dashboard: "Bảng thống kê",

          /* FILTER */
          Clear: "Đặt lại",
          Filter: "Lọc",
          "Select all": "Tất cả",
          "Conflict status of request with current action":
            "Xung đột trạng thái với request hiện tại",
          "Conflict status, cannot select all":
            "Xung đột trạng thái với request hiện tại, không thể chọn tất cả",

          /* RESULT */
          Release: "Gửi kết quả",
          Save: "Hoàn thành",
          "Choose a declaration statement for the selected product(s)":
            "Chọn để khai báo kết quả cho sản phẩm đã chọn",
          RESULT: "KẾT QUẢ",
          "Please enter maker name": "Vui lòng nhập người thực hiện",
          "Enter maker name...": "Nhập người thực hiện...",
          "Maker's name": "Người thực hiện",
          Never: "Chưa khai báo",
          "Please enter Cas no": "Vui lòng nhập số Cas",
          "Enter Cas No...": "Nhập số Cas...",
          "Please fill substances on this law above threshold":
            "Vui lòng điền chất vượt trên ngưỡng cho phép",
          SUBSTANCE: "CHẤT",
          "Concentration with maximum": "Nồng độ tối đa",
          Unit: "Đơn vị",
          "Enter concentration...": "Nhập nồng độ",
          "Please enter concentration...": "Vui lòng nhập nồng độ tối đa",
          "Select unit": "Chọn đơn vị",
          "Select substance": "Chọn chất",
          "Cas No.": "Số Cas",
          Substance: "Chất",
          "Please enter unit": "Vui lòng chọn đơn vị",
          ADD: "Thêm",
          "DOES NOT CONTAIN ": "KHÔNG CHỨA ",
          "CONTAIN ": "CHỨA ",
          "substances on this law above threshold":
            "các chất trên ngưỡng này theo luật này",
          "Provide supporting documentation for the selected product(s)":
            "Thêm tài liệu hỗ trợ cho kết quả đã khai báo",
          "UPLOAD FILE": "NHẬP TỆP",
          Minerals: "KHOÁNG CHẤT",
          "Please fill Minerals": "Vui lòng điền khoáng chất",
          "Please select a mineral": "Vui lòng chọn khoáng chất",
          "Supporting Documents": "Tài liệu hỗ trợ",
          EMPTY: "TRỐNG",
          "FOV requires that you provide the following documents":
            "FOV yêu cầu cung cấp tài liệu đính kèm cho kết quả",
          "Help Center": "Hỗ trợ",
          Setttings: "Cài đặt",
          "Please enter substance": "Vui lòng nhập chất",
          "Please enter mineral": "Vui lòng nhập khoáng chất",
          "Please upload a support file": "Vui lòng nhập tệp đính kèm",
          "List item selected is empty": "Danh sách item khai báo rỗng",
          "was exists": "Đã tồn tại",
          "Mineral already exists": "Khoáng chất đã tồn tại",
          "same value": "Giá trị đã tồn tại",
          "Save successfully!": "Lưu thành công",
          "Supplier Code": "Mã nhà cung cấp",
          "Company name": "Tên nhà cung cấp",
          "Phone number": "Số điện thoại",
          "Contact name": "Tên người liên hệ",
          Country: "Quốc gia",
          "Supplier Info": "Thông tin nhà cung cấp",
          "Web URL": "Địa chỉ Web",
          "Change password": "Đổi mật khẩu",
          "Old password": "Mật khẩu cũ",
          "New password": "Mật khẩu mới",
          "Confirm Password": "Xác nhận mật khẩu",
          "Re-type new password": "Nhập lại mật khẩu mới",
          "Enter old password...": "Nhập mật khẩu cũ...",
          "Enter new password...": "Nhập mật khẩu cũ...",
          Cancel: "Hủy bỏ",
          "mineral in the product with smelter in list":
            "Khoáng sản trong sản phẩm",
          "Requirements are selected is locked aim for update your result, please save or cancel your result before taking any action to unlock request.":
            "Yêu cầu đã khóa để cập nhật lại kết quả, vui lòng nhấn lưu hoặc hủy để mở khóa trước khi thực hiện các hành động khác",
          "Please upload new file after update your result":
            "Vui lòng tải tệp mới khi cập nhật lại kết quả",
          "Cannot find your result": "Không tìm thấy kết quả, vui lòng thử lại",
          "Please enter supplier code": "Vui lòng nhập mã nhà cung cấp",
          "Reset password": "Khôi phục mật khẩu mặc định",
          "Enter supplier code...": "Nhập mã nhà cung cấp",
          profile: "Hồ sơ",
          "Add your result failed": "Add your result failed",
          "Enter mineral in the product...": "Nhập khoáng chất",
          "Not found supplier": "Không tìm thấy mã nhà cung cấp",
          /* COMPLETE */
          "THANK YOU": "FOV CẢM ƠN",
          "Home Page": "Trang chủ",
          "Your result has been submitted, you can update your result before FOV check it!":
            "Kết quả của bạn đã được gửi đi, bạn có thể cập nhật lại kết quả cho tới khi FOV kiểm tra!",
          "items per page": "dòng trên trang",
          "Showing {0} of {1} items": "Hiển thị {0} trên {1} dòng",
          "Export to Excel": "Xuất thành Excel"
        },
      },
    },
    lng: language,
    fallbackLng: language,
    interpolation: {
      escapeValue: false,
    },
  });

  /* CALL API và lấy dữ liệu */
  const fetchData = async () => {
    try {
      var data = await getFovRequest();
      if (data) {
        setRequest(data);
        /* Truyền vào đếm not submit để truyền lên thông báo */
        setCountNotSubmit(
          data.filter((item) => item.statusID == STATUS_NOT_SUBMIT).length
        );
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

    
  return (
    <AppContext.Provider
      value={{
        request: request && request,
        setRequest: setRequest,
        setIsBlockClick: setIsBlockClick,
        currentSideHeading: [currentSideHeading, setCurrentSideHeading],
        tag: [tag, setTag],
        setIsShowFullSidebar: setIsShowFullSidebar,
        isShowFullSidebar: isShowFullSidebar,
        isShowController: isShowController,
        setIsShowController: setIsShowController,
        setSupplierCode: setSupplierCode,
        supplierCode: supplierCode,
        setLanguage: setLanguage,
        token: token,
        setToken: setToken,
        fetchData: fetchData,
      }}
    >
    {/* Nếu click hide side bar thì ẩn side bar bằng class "hideFullsidebar"*/}
      <div
        className={
          isShowFullSidebar ? "app_container" : "app_container hideFullSidebar"
        }
      >
        {/* Nếu showcontroller thì show sidebar và navigation không thì ẩn */}
        {isShowController && (
          <>
            <Sidebar
              isShowFullSidebar={isShowFullSidebar}
              setIsShowFullSidebar={setIsShowFullSidebar}
              currentSideHeading={currentSideHeading}
              setCurrentSideHeading={setCurrentSideHeading}
              tag={tag}
              setTag={setTag}
              isBlockClick={isBlockClick}
            />
            <Navigation
              setIsShowFullSidebar={setIsShowFullSidebar}
              isShowFullSidebar={isShowFullSidebar}
              currentSideHeading={currentSideHeading}
              countNotSubmit={countNotSubmit}
              isBlockClick={isBlockClick}
            />
          </>
        )}
        {/* Nếu show controller thì mới show các thuộc tính con còn không ẩn hết */}
        <div className={isShowController ? "app_main_content" : ""}>
          <AppRoutes />
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </AppContext.Provider>
  );
}

export default App;
