import React from "react";
//import css
import SearchModal from "./SearchModal";
import UserModal from "./UserModal";

import "../../Assets/CSS/Modal/Modal.css";
import LanguageModal from "./LanguageModal";
import NotificationModal from "./NotificationModal";

function Modal({ modalType, setIsShowModal, isBlockClick }) {
  const handleSetCloseModal = () => {
    setIsShowModal(false);
  };
  return (
    <>
      <div
        className={
          (modalType === "searchModal" && "modal_small_container") ||
          (modalType === "notificationModal" &&
            "modal_small_container notification_position") ||
          (modalType === "userModal" && "modal_small_container user_position")
        }
      >
        {modalType === "searchModal" && <SearchModal />}
        {modalType === "notificationModal" && <NotificationModal />}
        {modalType === "userModal" && (
          <UserModal
            setIsShowModal={setIsShowModal}
            isBlockClick={isBlockClick}
          />
        )}
        {modalType === "languageModal" && <LanguageModal />}
      </div>
      <div className="modal_small_overlay" onClick={handleSetCloseModal}></div>
    </>
  );
}

export default Modal;
