import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

//import react-icon
import { BsFillBellFill } from "react-icons/bs";
import { BiSolidUser } from "react-icons/bi";
import { LuLanguages } from "react-icons/lu";
import { HiMenuAlt3 } from "react-icons/hi";
import { FaAngleDown } from "react-icons/fa";
import { AppContext } from "../../../App";
//import css
import "../../../Assets/CSS/Control/Navigation.css";
import Modal from "../../Modals/Modal";
import { STATUS_NOT_SUBMIT } from "../../../Constraint/constraint";
import { useNavigate } from "react-router-dom";

function Navigation({ isShowFullSidebar, currentSideHeading, isBlockClick }) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const request = appContext.request;
  const supplierCode = appContext.supplierCode;

  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [filterData, setFilterData] = useState(
    request.filter((item) => item.statusID == STATUS_NOT_SUBMIT)
  );

  const handleShowSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("display_block");
  };

  const handleShowModal = (modalType) => {
    setIsShowModal(true);
    setModalType(modalType);
  };

  const handleNof = (modalType) => {
    if (!isBlockClick) {
      setIsShowModal(true);
      setModalType(modalType);
      //navigate("/supplier-request");
    }
  };

  return (
    <div
      className={
        isShowFullSidebar
          ? "navigation_container"
          : "navigation_container hideFull"
      }
    >
      <span onClick={handleShowSidebar} className="navigation_menu">
        <HiMenuAlt3 />
      </span>
      <div className="navigation_current_page">{t(currentSideHeading)}</div>
      <div
        id="nof_container"
        onClick={() => handleShowModal("languageModal")}
        className="language_container"
      >
        <div className="language_item">
          <LuLanguages className="bell_icon" />
        </div>
      </div>
      {/* <div
        onClick={() => handleShowModal("searchModal")}
        id="search_container"
        className="search_container"
      >
        <CiSearch className="search_icon" />
      </div> */}
      <div
        onClick={() => handleShowModal("notificationModal")}
        id="nof_container"
        className="bell_container"
      >
        <div className="nof_item">
          <BsFillBellFill className="bell_icon" />
          {
            <span>
              {request &&
                request.filter((item) => item.statusID == STATUS_NOT_SUBMIT)
                  .length > 0 &&
                request.filter((item) => item.statusID == STATUS_NOT_SUBMIT)
                  .length}
            </span>
          }
        </div>
      </div>
      <div
        onClick={() => handleShowModal("userModal")}
        id="user_container"
        className="user_container"
      >
        {supplierCode ? (
          <span>
            {supplierCode}
            <FaAngleDown />
          </span>
        ) : (
          <BiSolidUser className="user_icon" />
        )}
      </div>
      {isShowModal && (
        <Modal
          data={filterData}
          isBlockClick={isBlockClick}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          modalType={modalType}
        />
      )}
    </div>
  );
}

export default Navigation;
