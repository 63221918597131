import axios from "../Setup/axios";

const login = (supplierCode, pass) => {
  return axios.post(`/api/authentication/login`, {
    SupplierCode: supplierCode,
    Password: pass,
  });
};

const generateRefreshToken = () => {
  return axios.post(`/api/authentication/generate-refresh-token`);
};

const logout = () => {
  return axios.post(`/api/authentication/logout`);
};

export { login, logout, generateRefreshToken };
