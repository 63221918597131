import axios from "../Setup/axios";

const getFovRequest = () => {
  return axios.get("/api/fov-requests/supplier-code");
};

const updateStatusRequest = (fovRequests, newStatusID, supplierComment) => {
  return axios.post(`/api/fov-requests/status`, {
    fovRequests: fovRequests,
    newStatus: newStatusID,
    supplierComment: supplierComment,
  });
};

const removeReturnFovRequest = () => {
  return axios.post("/api/fov-requests/remove/return-request");
};

const lockRequest = (fovRequestID, isLock) => {
  return axios.post(
    `/api/fov-requests/lock-request?fovRequestID=${fovRequestID}&isLock=${isLock}`
  );
};

export {
  getFovRequest,
  updateStatusRequest,
  lockRequest,
  removeReturnFovRequest,
};
