import React, { lazy, Suspense } from "react";

import Cookies from "js-cookie";
import jwt from "jwt-decode";

//import library
import { Route, Routes } from "react-router-dom";
import "../Assets/CSS/Reset.css";
import Loading from "../Components/Loading/Loading";

//import page

const SupplierContainer = lazy(() =>
  import("../Pages/Supplier/SupplierContainer")
);

/* Import component with lazy loading */
const RequestPage = lazy(() => import("../Pages/RequestPage/RequestPage"));
const CompletePage = lazy(() => import("../Pages/CompletePage/CompletePage"));
const LoginPage = lazy(() => import("../Pages/LoginPage/LoginPage"));
const NotFound = lazy(() => import("../Pages/NotFound/NotFound"));
const HistoryPage = lazy(() => import("../Pages/HistoryPage/HistoryPage"));
const ProfilePage = lazy(() => import("../Pages/ProfilePage/ProfilePage"));
const FormDownloadPage = lazy(() => import("../Pages/FormDownloadPage/FormDownloadPage"));


function AppRoutes(props) {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/dashboard" element={<SupplierContainer />} />
        <Route exact path="/supplier-request" element={<RequestPage />} />
        <Route exact path="/complete" element={<CompletePage />} />
        <Route exact path="/history" element={<HistoryPage />} />
        <Route exact path="/profile" element={<ProfilePage />} />
        <Route exact path="/form-download" element={<FormDownloadPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
