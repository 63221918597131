import React from "react";
import { useTranslation } from "react-i18next";

//import react-icon
import { BiSolidDashboard, BiSupport } from "react-icons/bi";
import { MdWorkHistory } from "react-icons/md";
import { IoIosCloudDownload } from "react-icons/io";
import { GrMail } from "react-icons/gr";
import { PiCaretRightBold } from "react-icons/pi";
import { PiCaretLeftBold } from "react-icons/pi";

//import css
import "../../../Assets/CSS/Control/Control.css";
import "../../../Assets/CSS/Control/Sidebar.css";
import { Link } from "react-router-dom";

//import image
import fujikuraLogo from "../../../Assets/IMG/fujikuraLogo.png";

function Sidebar({
  setCurrentSideHeading,
  isShowFullSidebar,
  setIsShowFullSidebar,
  tag,
  isBlockClick,
  setTag,
}) {
  const { t } = useTranslation();

  //handle on click tag sidebar
  // Change table side and active the item in sidebar
  const handleSidebarItemOnClick = (event, Dashboard) => {
    if (!isBlockClick) {
      setTag(event.currentTarget.id);
      setCurrentSideHeading(Dashboard); // get state from container
    }
  };

  const handleShowSidebar = () => {
    const flag = isShowFullSidebar;
    setIsShowFullSidebar(!flag);
  };

  return (
    <div
      id="sidebar"
      className={
        isShowFullSidebar ? "sidebar_container" : "sidebar_container hideFull"
      }
    >
      <div className="sidebar_header">
        <div className={"sidebar_heading_avatar_container"}>
          <img src={fujikuraLogo} />
        </div>
        <div className="sidebar_heading_infor">
          <h6>Fujikura</h6>
          <p>Fujikura Fiber Optics Viet Nam</p>
        </div>
      </div>

      <div className="sidebar_body">
        <div
          onClick={handleShowSidebar}
          className={"sidebar_heading_menu_icon_container"}
        >
          {isShowFullSidebar ? <PiCaretLeftBold /> : <PiCaretRightBold />}
        </div>
        <div className={"sidebar_body_list"}>
          <Link
            id="sidebar_item_id_0"
            to={!isBlockClick && "/dashboard"}
            className={
              tag == "sidebar_item_id_0" && !isBlockClick
                ? "sidebar_body_item active"
                : "sidebar_body_item"
            }
            onClick={(e) => handleSidebarItemOnClick(e, "Dashboard")}
          >
            <BiSolidDashboard className="sidebar_body_item_icon" />
            <p>{t("Dashboard")}</p>
          </Link>
          <Link
            id="sidebar_item_id_1"
            to={!isBlockClick && "/supplier-request"}
            className={
              tag == "sidebar_item_id_1" && !isBlockClick
                ? "sidebar_body_item active"
                : "sidebar_body_item"
            }
            onClick={(e) => handleSidebarItemOnClick(e, "Request")}
          >
            <GrMail className="sidebar_body_item_icon" />
            <p>{t("Request")}</p>
          </Link>

          <Link
            id="sidebar_item_id_2"
            to={!isBlockClick && "/history"}
            className={
              tag == "sidebar_item_id_2" && !isBlockClick
                ? "sidebar_body_item active"
                : "sidebar_body_item"
            }
            onClick={(e) => handleSidebarItemOnClick(e, "History")}
          >
            <MdWorkHistory className="sidebar_body_item_icon" />
            <p>{t("History")}</p>
          </Link>

          <Link
            id="sidebar_item_id_3"
            to={!isBlockClick && "/form-download"}
            className={
              tag == "sidebar_item_id_3" && !isBlockClick
                ? "sidebar_body_item active"
                : "sidebar_body_item"
            }
            onClick={(e) => handleSidebarItemOnClick(e, "Form Download")}
          >
            <IoIosCloudDownload className="sidebar_body_item_icon" />
            <p>{t("Form Download")}</p>
          </Link>
        </div>
      </div>
      <div className="sidebar_footer">
        <div className="sidebar_footer_item">
          {/* <BiSupport className="sidebar_body_item_icon" />
          <span>{t("Help Center")}</span> */}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
